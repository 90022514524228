<template>
  <div class="content">
    <div class="page">
      <h2 style="margin-bottom: 30px; font-size: 20px">指挥调度</h2>
      <div class="img">
        <img src="../../../../assets/image/fbiImg/left.png" alt="" />
        <img src="../../../../assets/image/fbiImg/right.png" alt="" />
      </div>
    </div>
    <div class="four_num">
      <div class="son">
        <h2>辖区情况“一张图”</h2>
        <span
          >辖区情况“一张图”展示，警务人员可在警情案件发生的第一时间辖区内的范围内的视频监控点位，提高办案效率。</span
        >
      </div>
      <div class="son">
        <h2 class="son_two">警力分布“一张图”</h2>
        <span
          >警力分布“一张表”，一旦发生警情事件可直接交办到人，实现灵活的指挥调配。</span
        >
      </div>
      <div class="son">
        <h2>警情分流任务管理</h2>
        <span
          >快速本辖区不同时间段的案件，并制定解决方案，最大限度的节省时间。</span
        >
      </div>
      <div class="son">
        <h2 class="son_two">多终端案件受理</h2>
        <span
          >案件的受理、指派、上报等操作可通过多终端实现；警员可及时接收到案件详情，并可调看案发现场监控。</span
        >
      </div>
      <img
        src="../../../../assets/image/fbiImg/center.png"
        alt=""
        class="center"
      />
    </div>
    <div class="page">
      <div class="img">
        <img src="../../../../assets/image/fbiImg/left_one.png" alt="" />
        <img src="../../../../assets/image/fbiImg/right_two.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  padding: 0 10%;
  margin-bottom: 50px;
  .page {
    margin-top: 50px;
  }
  .img {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    img {
      width: 49%;
    }
  }
  .four_num {
    padding: 0 5%;
    position: relative;
    margin-top: 20px;
    height: 400px;

    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
  .son {
    padding: 30px 15px 10px 15px;
    width: 45%;
    height: 110px;
    background-color: #fff;
    h2 {
      color: #3651d8;
      margin-bottom: 20px;
    }
    .son_two {
      text-align: right;
    }
    span {
      color: #6b6b6b;
    }
  }
  .img_demo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    h2 {
      font-weight: bold;
      margin: 50px 30px 10px 50px;
    }
    .message {
      color: #6b6b6b;
      font-size: 16px;
      margin: 20px 30px 10px 50px;
    }
    .right {
      width: 50%;

      height: 400px;
      background-color: #fff;
    }
  }
  .shuju {
    margin-top: 20px;
    .message {
      margin-top: 10px;
    }
    .imgs {
      margin-top: 40px;
      padding: 0 5%;
      display: flex;
      justify-content: space-between;
      .son_img {
        position: relative;
        margin: 30px 0;
      }
      .sebei_one {
        position: absolute;
        bottom: -8%;
        right: -5%;
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background-color: #8095c9;
        color: #fff;
        font-size: 18px;
      }
      .sebei_two {
        position: absolute;
        top: -10%;
        right: -5%;
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background-color: #8095c9;
        color: #fff;
        font-size: 18px;
      }
    }
  }
}
</style>
